import React from 'react';

export default class EstimateCTASmall extends React.Component{

    constructor(props){
       super(props);
    }

    _submitCTA(e){
        document.getElementById('step1-email').value = this.refs.email.value;
        document.querySelector('.modal--get-estimate').classList.toggle('modal--is-active');
        document.body.classList.add('modal--open');
    }

    render(){
        return(
            <div>
                <section className="subscribe subscribe--start-consultation">
                    <div className="flex-row">
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="col-md-12 col-lg-12 text-center">
                                <div>
                                <div className="subscribe-box subscribe-box--start-consultation subscribe--start-consultation-small">
        
                                    <div className="subsribe-box__input-group">
                                        <input type="email" ref="email" className="subscribe-box__text" placeholder="Enter your email" required="required" />
                                        <button data-conversion-element="CTA with Email Input" className="js-conversion-element btn subscribe-box__submit" onClick={this._submitCTA.bind(this)}> <span style={{ position: "relative", top: "-1px" }}>get started</span> <span className="link-arrow">→</span></button>
                                    </div>
                                    <p>no up-front costs. no obligation. no hassle.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        )

    }

}