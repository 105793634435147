import React from 'react';
import EmailInputWithButton from "./EmailInputWithButton";

export default class CTAModal extends React.Component {

    _closeModal() {
        document.querySelector('.modal--oui').classList.toggle('modal--is-active');
        document.body.classList.remove('modal--oui--open');
    }

    render() {
        return (
            <div className="modal fade modal--oui modal--small d-flex align-items-center justify-content-between" id="get-estimate">

                <div className="get-estimate-close modal-close js-modal-close" onClick={this._closeModal}></div>

                <div className="modal--oui__image-container">
                    <div className="modal--oui__background"></div>
                    <div className="modal--oui__image-container__text">
                        <h1>Get a free in-home consultation for your renovation</h1>
                        <p>You’ll get a free, personalized fixed-price proposal for your renovation.
                            <br /> There's no up-front costs and no obligation.</p>
                    </div>
                </div>

                <EmailInputWithButton conversionElement="Exit Intent CTA with Email"  ouiPopup={true} withRenovatorCallText={true} />

            </div>
        )
    }
}



