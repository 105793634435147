import React from 'react';

export default class EmailInputWithButton extends React.Component {
    constructor(props) {
        super(props)
    }

    _submitCTA(e){
        e.preventDefault();

        document.getElementById('step1-email').value = this.refs.email.value;

        if(this.props.ouiPopup) {
            document.body.classList.toggle('modal--oui--open');
            document.querySelector('.modal--oui').classList.toggle('modal--is-active');
        }

        document.querySelector('.modal--get-estimate').classList.toggle('modal--is-active');
        document.body.classList.add('modal--open');

        return false;
    }

    render() {
        return (
            <span>
                <div>
                    <input type="email" ref="email" id="get-started__email" className="subscribe-box__text" placeholder="Enter your email" />
                    <button data-conversion-element={this.props.conversionElement || 'CTA with Email Input'} className="btn subscribe-box__submit" onClick={this._submitCTA.bind(this)}>
                        <span style={{position: "relative", top: "-1px"}}>get started</span> <span className="link-arrow">→</span>
                    </button>
                </div>

                {this.props.withRenovatorCallText && (
                    <a href="tel:6138946554" className="top-nav__link top-nav__link--contact">Talk to a renovator (613) 894-6554</a>
                )}

            </span>
        )

    }
}

EmailInputWithButton.defaultProps = {
    ouiPopup: false,
    withRenovatorCallText: false,
    conversionElement: ''
}