import React from 'react';
import EmailInputWithButton from "./EmailInputWithButton";
import axios from 'axios';
import $ from "jquery";
import validate from 'jquery-validation';
import autoBind from 'react-autobind';

export default class DocumentRequestPopup extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            formSubmittedSuccessfully: false,
            documentRequestFormSubmitting: false,
        }

        autoBind(this);
    }

    componentDidMount(){

        $('.documents-request-form').validate({

            rules: {
                documentRequestEmail: {
                    email: true,
                    required: true,
                },
                documentRequestName: {
                    required: true,
                    maxlength: 15,
                }
            },

        });
    }

    _submitDocumentRequestCTA(e) {
        console.log("YES DRTA");

        e.preventDefault();

        this.setState({
            documentRequestFormSubmitting: true,
        })

        axios.post('/document-request', {
            firstName: document.getElementById('document-request__name').value.trim(),
            email: document.getElementById('document-request__email').value.trim(),
            documentRequested: localStorage.getItem('documentRequested'),
        }).then((response) => {

            console.log(response);

            this.setState({
                 formSubmittedSuccessfully: true,
                documentRequestFormSubmitting: false,
            });

            localStorage.setItem('alreadySubmittedForm', true)

        }).catch((error) => {
            console.log(error);
            this.setState({
                documentRequestFormSubmitting: false,
            })
        });
    }

    _closeModal() {
        document.body.classList.remove('modal--open');
        document.querySelector('.comapny-document-request-popup-container').classList.toggle('modal--is-active');
    }

    render() {
        return (
            <div className="document-request-popup">

                <div className="get-estimate-close document-request-popup-close modal-close js-modal-close" onClick={this._closeModal}></div>

                {this.state.formSubmittedSuccessfully ? (
                    <React.Fragment>
                        <p className="document-request-popup__success-text">Your request for documents was sent successfully. I will be in touch shortly to send you these files.</p>
                    </React.Fragment>
                ) : (
                    <form onSubmit={(e) => this._submitDocumentRequestCTA(e)} className="documents-request-form document-request-popup__inner-container">
                        <p className="document-request-popup__text">
                            <strong>Download our credentials</strong>. You’re in good hands, but don’t take my word for it.
                        </p>
                        <div className="document-request-popup__fields">
                            <input required type="text" ref="firstName" id="document-request__name" name="documentRequestName" className="subscribe-box__text" placeholder="Your first name" />

                            <input required type="email" ref="email" id="document-request__email" name="documentRequestEmail" className="subscribe-box__text" placeholder="Enter your email" />
                            <button disabled={this.state.documentRequestFormSubmitting} className="btn subscribe-box__submit" onClick={this._submitDocumentRequestCTA.bind(this)}>
                            <span style={{position: "relative", top: "-1px"}}>send</span> <span className="link-arrow">→</span>
                            </button>
                        </div>
                    </form>
                )}

            </div>
        )
    }
}



