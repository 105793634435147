import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

import EstimateCTA from './components/Common/EstimateCTA.jsx';
import EstimateCTASmall from './components/Common/EstimateCTASmall.jsx';
import CTAModal from './components/Common/CTAModal';
import DocumentRequestPopup from './components/Common/DocumentRequestPopup';


$(document).ready(function(){
	if(document.querySelectorAll('.consultation-wrapper-small').length > 0){
        ReactDOM.render(<EstimateCTASmall />, document.querySelector('.consultation-wrapper-small'));
	}

	if(document.querySelectorAll('.consultation-wrapper').length > 0){
        ReactDOM.render(<EstimateCTA />, document.querySelector('.consultation-wrapper'));
	}

    if(document.querySelectorAll('.ouimodal-render').length > 0){
        ReactDOM.render(<CTAModal />, document.querySelector('.ouimodal-render'));
    }

    if(document.querySelectorAll('.company-document-request-popup-wrapper').length > 0){
        ReactDOM.render(<DocumentRequestPopup />, document.querySelector('.company-document-request-popup-wrapper'));
    }
});
