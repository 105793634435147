import React from 'react';
import EmailInputWithButton from '../Common/EmailInputWithButton';

export default class EstimateCTA extends React.Component{

    constructor(props){
       super(props);

    }

    render(){

        return(
            <div>
                <section className="subscribe subscribe--start-consultation">
                    <div className="flex-row">
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="col-md-12 col-lg-12 text-center">
                                <div>
                                    <div className="subscribe-box subscribe-box--start-consultation">
                                        <h3>Start your free in-home consultation</h3>
                                            <div className="subsribe-box__input-group">
                                                <EmailInputWithButton conversionElement="CTA with Email Input Footer" />
                                            </div>
                                            <p>no up-front costs. no obligation. no hassle.</p>
                                            <div className="subscribe--start-consultation__reivew">
                                                <div className="subscribe--start-consultation__reivew-stars">
                                                    <img src="/resources/front/img/elfarro-homestars-review-star.svg" alt="Elfarro homestars review star" />
                                                    <img src="/resources/front/img/elfarro-homestars-review-star.svg" alt="Elfarro homestars review star" />
                                                    <img src="/resources/front/img/elfarro-homestars-review-star.svg" alt="Elfarro homestars review star" />
                                                    <img src="/resources/front/img/elfarro-homestars-review-star.svg" alt="Elfarro homestars review star" />
                                                    <img src="/resources/front/img/elfarro-homestars-review-star.svg" alt="Elfarro homestars review star" />

                                                </div>

                                                <div>
                                                    <p className="subscribe--start-consultation__reivew__rating">9.8/10</p>
                                                </div>

                                                <div>
                                                    <a target="_blank" href="https://homestars.com/companies/2872996-Elfarro-Design-Build" className="subscribe--start-consultation__reivew__link">See our reviews (+35)</a>
                                                </div>
                                            </div>

                                            <div style={{ margin: '0 auto 3em' }} className="intro-home__text__homestaraward intro-home__text__homestaraward--first">
                                                <a href="https://homestars.com/companies/2872996-Elfarro-Design-Build" target="_blank">
                                                    <img src="https://elfarro.com/resources/front/img/elfarro-homestars-best-of-2020.png" width="76"/>
                                                </a>
                                                <p>For four years in a row, Elfarro Design-Build has won
                                                    HomeStars Best of Award in Ottawa celebrating its commitment to quality
                                                    work and service.</p>
                                            </div>

                                            <div style={{ margin: '0 auto 3em' }} className="intro-home__text__homestaraward intro-home__text__homestaraward--second">
                                                <a href="https://homestars.com/companies/2872996-Elfarro-Design-Build" target="_blank">
                                                    <img src="https://elfarro.com/resources/front/img/elfarro-homestars-best-of-2020.png" width="76"/>
                                                </a>
                                                <p>For four years in a row, Elfarro Design-Build has won
                                                    HomeStars Best of Award in Ottawa celebrating its commitment to quality
                                                    work and service.</p>
                                            </div>

                                    </div>

                                    </div>    
                                </div>
                             
                            </div>
                        </div>

                </section>

            </div>

        )

    }

}